<template>
  <div style="height: inherit">

    <!-- Filters -->
    <!--    <users-list-filters-->
    <!--      :gender-filter.sync="genderFilter"-->
    <!--      :status-filter.sync="statusFilter"-->
    <!--      :gender-options="genders"-->
    <!--      :status-options="status"-->
    <!--      :view-type.sync="viewType"-->
    <!--    />-->

    <commissions-table />
  </div>
</template>

<script>

// eslint-disable-next-line import/extensions
import Ripple from 'vue-ripple-directive'
import useOrderList from '@/views/ordini/useOrderList'
import CommissionsTable from '@/views/commissioni-richieste/commissioni-list/CommissionsTable.vue'

export default {
  name: 'Commissions',
  components: {
    CommissionsTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      // Options
      typeOptions,
      statusOptions,
      siNoOptions,
      //
      fetchOrdini,
      refetchOrdine,
      getListOrdini,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdiniListTable,
      refetchData,

      // Extra Filters
      //
    } = useOrderList()

    return {
      // Options
      typeOptions,
      statusOptions,
      siNoOptions,
      //
      fetchOrdini,
      refetchOrdine,
      getListOrdini,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdiniListTable,
      refetchData,

      // Extra Filters
      //
    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
