<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <b-col
          v-if="$can('users','Users')"
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refCommissioniListTable"
      class="position-relative mh-200"
      :items="fetchCommissioni"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessuna richiesta trovata"
      :sort-desc.sync="isSortDirDesc"
    >

      <template
        v-if="$can('users', 'Users')"
        #cell(utente)="data"
      >
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.user.fullName }}</span>
          <span class="text-muted d-block">
            {{ data.item.user.code }}
          </span>
        </div>
      </template>

      <template #cell(stato)="data">
        <div class="text-nowrap">
          <b-dropdown
            v-if="$can('update', 'Commissions')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <b-badge
                :variant="statusResolver(data.item.status)"
                class="badge"
              >
                {{ statusNameResolver(data.item.status) }}
              </b-badge>
            </template>

            <b-dropdown-item
              v-if="data.item.status !== 'Payed'"
              @click="updateStatus(data.item, 'payed')"
            >
              <feather-icon icon="CheckIcon" />
              <span
                class="align-middle ml-50"
              >Pagato</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status !== 'Pending'"
              @click="updateStatus(data.item, 'pending')"
            >
              <feather-icon icon="CreditCardIcon" />
              <span
                class="align-middle ml-50"
              >Da Pagare</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-badge
            v-else
            :variant="statusResolver(data.item.status)"
            class="badge ml-1"
          >
            {{ statusNameResolver(data.item.status) }}
          </b-badge>
        </div>
      </template>

      <template #cell(richiesto)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.requested }}</span>
        </div>
      </template>

      <template #cell(commissionabile)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.tot_commissions_without_requested }}</span>
        </div>
      </template>

      <template #cell(commissionabile_prima_della_richiesta)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.tot_commissions }}</span>
        </div>
      </template>

      <template #cell(inoltrata_il)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInput,
  BBadge,
  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useCommissionList from '@/views/commissioni-richieste/useCommissionList'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CommissionsTable',
  components: {
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {},
  mounted() {
  },
  setup() {
    const toast = useToast()

    const {
      // Options

      //
      fetchCommissioni,
      refetchCommissione,
      getListCommissioni,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCommissioniListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
      tableColumnsResolver,

    } = useCommissionList()
    tableColumnsResolver()

    const updateStatus = (item, newStatus) => {
      store.dispatch('commissions/updateCommissione', {
        id: item.id,
        status: newStatus,
      })
        .then(response => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      // Options

      //
      fetchCommissioni,
      refetchCommissione,
      getListCommissioni,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCommissioniListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
      tableColumnsResolver,

      updateStatus,
    }
  },
  methods: {},
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
