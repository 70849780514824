import { render, staticRenderFns } from "./Commissions.vue?vue&type=template&id=65fc6875&scoped=true&"
import script from "./Commissions.vue?vue&type=script&lang=js&"
export * from "./Commissions.vue?vue&type=script&lang=js&"
import style0 from "./Commissions.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Commissions.vue?vue&type=style&index=1&id=65fc6875&lang=scss&scoped=true&"
import style2 from "./Commissions.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65fc6875",
  null
  
)

export default component.exports